<template>
  <div class="users nj-grid">
    <div class="header">
      <v-form @submit.prevent="search()">
        <div class="d-flex flex-row">
          <nj-chip-select-input
            class="mr-2"
            default-text="Status"
            :items="statusOptions"
            v-model="form.status"
          ></nj-chip-select-input>
          <nj-date-interval-input
            class="mr-2"
            label="Data de Cadastro"
            ref="creationIntervalInput"
            chip
            hide-details
            @change="chageCreationInterval"
            :default-start-at="defaultStartAt"
            :default-ends-at="defaultEndsAt"
          />
        </div>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Buscando..."
      ref="dataTable"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | createdAt }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from 'moment'
import Api from '@/resources/NajaSocialApi'
const today = moment()

const STATUS_OPTIONS = [
  { value: '', text: 'Todos' },
  { value: 'error', text: 'Erro' },
  { value: 'sent', text: 'Enviada' },
  { value: 'pending', text: 'Pendente' },
  { value: 'running', text: 'Notificação criada' },
  { value: 'canceled', text: 'Cancelada' },
]

export default {
  name: 'InboxMessages',

  watch: {
    form: {
      handler() {
        this.search()
      },
      deep: true,
    },
  },

  filters: {
    createdAt: function (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
  },

  data() {
    return {
      searchTerm: '',
      loading: false,
      items: [],
      statusOptions: STATUS_OPTIONS,
      defaultStartAt: today.format('DD/MM/YYYY'),
      defaultEndsAt: today.format('DD/MM/YYYY'),
      form: {
        created_at_gteq: null,
        created_at_lteq: null,
        status: null,
      },
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Dt/Hr da criação', value: 'created_at' },
        {
          text: 'Configuração de notificação',
          value: 'notification_setting_description',
        },
        { text: 'Evento', value: 'notification_setting_event' },
        { text: 'Cod. Fator Gerador', value: 'code_generator' },
        { text: 'Status', value: 'human_state_name' },
      ],
    }
  },

  mounted() {
    // this.fetch()
    // this.search()
  },

  methods: {
    chageCreationInterval(interval) {
      this.form.created_at_gteq = interval[0]
      this.form.created_at_lteq = interval[1]
    },

    search() {
      this.loading = true

      const query = {
        q: {
          s: ['created_at desc'],
          state_eq: this.form.status,
        },
      }

      if (
        this.form.created_at_gteq != null &&
        this.form.created_at_lteq != null
      ) {
        query.q.created_at_gteq = `${this.form.created_at_gteq} 00:00`
        query.q.created_at_lteq = `${this.form.created_at_lteq} 23:59`
      }

      Api.inboxMessages
        .search(query)
        .then(response => {
          this.items = response.data.inbox_messages
        })
        .catch(error => {
          if (error.response?.status === 400) {
            this.$root.$children[0].toast(
              error.response.data.error || error.response.data.warn
            )
          }
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>

<style scoped>
.header .v-form {
  width: 100%;
  display: flex;
}
</style>
